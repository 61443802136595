import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '@browniebroke/gatsby-image-gallery';
import Layout from '../../components/Layout';
import ScrollToTop from 'react-scroll-up';

const Photos = ({ data, location }) => {
  // console.log(data);
  const picturesArray = data.photosPage.edges;
  const images = data.photosPage.edges.map(({ node }) => node.childImageSharp);

  return (
    <Layout fullMenu location={location} title="Engagement Photos">
      <article id="main">
        <header>
          <h2>Engagement Photos</h2>
          <p>Taken in various locations around Austin, Texas</p>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <div className="box alt">
              <p>
                <Link to="/photos">&larr; Back to Photos</Link>
              </p>
              {/* <div className="row gtr-50 gtr-uniform"> */}
              <div className="gallerya">
                <Gallery images={images} colWidth={100} mdColWidth={25} />
                {/* {picturesArray.map((image) => {
                  const img = getImage(image.node);
                  return (
                    <GatsbyImage
                      image={img}
                      alt={image.node.name}
                      key={image.node.name}
                      // onClick={() =>
                      //   window.open(
                      //     image.node.childImageSharp.gatsbyImageData.images
                      //       .fallback.src,
                      //     '_blank'
                      //   )
                      // }
                    />
                  );
                })} */}
                {/* {picturesArray.map((image) => {
                  const img = getImage(image.node);
                  return (
                    <GatsbyImage
                      image={img}
                      alt={image.node.name}
                      key={image.node.name}
                    />
                  );
                })} */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </section>
      </article>
      <ScrollToTop showUnder={160} duration={700}>
        <div className="button primary scroll-up-button">
          <span className="icon solid fa-arrow-up" />
        </div>
      </ScrollToTop>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    photosPage: allFile(
      filter: { sourceInstanceName: { eq: "photosPage" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          name
          childImageSharp {
            thumb: gatsbyImageData(
              placeholder: BLURRED
              aspectRatio: 0.75
              width: 270
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
// export const pageQuery = graphql`
//   query {
//     photosPage: allFile(
//       filter: { sourceInstanceName: { eq: "photosPage" } }
//       sort: { order: ASC, fields: name }
//     ) {
//       edges {
//         node {
//           name
//           childImageSharp {
//             gatsbyImageData(placeholder: BLURRED)
//           }
//         }
//       }
//     }
//   }
// `;

export default Photos;
